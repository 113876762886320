<template>
  <Form :labelWidth="100" :data="formData">
    <FormItem label="名称">
      <Input v-model="formData.name" placeholder="名称" />
    </FormItem>
    <FormItem label="显示标题">
      <Input v-model="formData.title" placeholder="显示标题" />
    </FormItem>
    <FormItem label="权限">
      <div style="height: 50%; overflow-y: auto;">
        <Tree
          ref="pTree"
          :data="permissionTree"
          multiple
          show-checkbox
          :render="permissionRender"
          @on-check-change="permissionChanged"
          style="height: 400px; overflow-y: auto; padding: 10px; border: 1px solid #ccc;"
        />
      </div>
    </FormItem>
  </Form>
</template>

<script>
import { queryPermissions } from "@/api"
import { apiUtil, enableInTree, disableExcept } from "@/util"
const initData = () => {
  return {
    name: "",
    title: "",
    roleAlias: "",
    permissions: [],
  }
}
export default {
  name: "RoleEditor",
  components: {},
  props: {},
  data() {
    return {
      permissionTree: [],
      formData: Object.assign({}, initData()),
      permissionRender(h, { node }) {
        return h(
          "span",
          {
            style: {
              display: "inline-block",
              width: "100%",
              fontWeight: "bold",
            },
          },
          [
            node.node.title,
            h("span", { style: { fontWeight: "normal", marginLeft: "10px", color: "#999" } }, node.node.comment),
          ]
        )
      },
    }
  },
  computed: {},
  watch: {
    formData: {
      deep: false,
      async handler(newV) {
        this.permissionTree = await this.reloadPermissionTree(newV.permissions)
        this.updatePermissionTreeChecked(newV.permissions)
      },
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      apiUtil.call(this, async () => {
        this.permissionTree = await this.reloadPermissionTree(this.formData.permissions)
      })
    },
    async reloadPermissionTree(permissions) {
      const treeData = await queryPermissions()
      treeData.forEach(n => enableInTree(n, permissions))
      return treeData
    },
    async doUpdate() {
      try {
        if (!this.formData.alias) throw new Error("数据错误")
        this.$Message.success("更新成功")
        this.$emit("updated")
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    permissionChanged(selected) {
      const permissions = selected
        .filter(p => p.children == null || p.children.length == 0)
        .map(p => p.name)
        .reduce((acc, cur) => {
          if (acc.includes("ADMIN") || acc.includes("SUPER")) {
            return acc
          }
          if (cur == "ADMIN" || cur == "SUPER") {
            return [cur]
          }
          return [...acc, cur]
        }, [])
      this.updatePermissionTreeChecked(permissions)
      this.formData.permissions = permissions
      console.log(permissions)
    },
    updatePermissionTreeChecked(permissions) {
      if (permissions.includes("SUPER") || permissions.includes("ADMIN")) {
        this.permissionTree.forEach(p => {
          disableExcept(p, permissions)
        })
      } else {
        this.permissionTree.forEach(p => {
          delete p.disabled
          delete p.disableCheckbox
        })
      }
      this.$refs.pTree.$forceUpdate()
    },
  },
}
</script>

<style></style>
