<template>
  <ContentWrapper :links="topLinks">
    <Form inline>
      <FormItem>
        <ButtonGroup size="large">
          <Button type="primary" @click="dialogDisplay = true">新建</Button>
        </ButtonGroup>
      </FormItem>
    </Form>
    <List border>
      <ListItem v-for="roleItem in allRoles" :key="roleItem.name">
        <ListItemMeta :title="roleItem.title">
          <template slot="description">
            <Tag
              v-for="p in roleItem.permissions"
              :key="p"
              :color="p == 'SUPER' ? 'red' : p == 'ADMIN' ? 'orange' : 'green'"
            >
              {{ permissionMap[p].title }}
            </Tag>
          </template>
        </ListItemMeta>
        <template slot="action">
          <li>
            <a @click="edit(roleItem)">编辑</a>
          </li>
          <li>
            <a @click="deleteByAlias(roleItem.roleAlias)">删除</a>
          </li>
        </template>
      </ListItem>
    </List>
    <Modal v-model="dialogDisplay" :title="dialogTitle" @on-ok="onFormSubmit">
      <RoleEditor ref="roleEditor" />
    </Modal>
  </ContentWrapper>
</template>

<script>
import ContentWrapper from "@/components/ContentWrapper.vue"
import RoleEditor from "@/components/admin/RoleEditor.vue"
import { queryAllRolesWithPermissions, queryAllPermissions, createRole, updateRole, deleteRole } from "@/api"
import { apiUtil } from "@/util"

export default {
  name: "RoleQueryPage",
  components: { ContentWrapper, RoleEditor },
  data() {
    return {
      allRoles: {},
      permissionMap: {},
      topLinks: [{ title: "角色管理", link: this.$route.path }],
      isLoading: false,
      dialogDisplay: false,
      dialogTitle: "创建角色",
      dialogMode: "NEW",
    }
  },
  computed: {},
  watch: {
    async page() {
      await this.reload()
    },
    queryForm: {
      deep: true,
      async handler() {
        await this.reload()
      },
    },
  },
  async mounted() {
    await this.reload()
  },
  methods: {
    async reload() {
      try {
        this.isLoading = true
        this.permissionMap = await queryAllPermissions()
        this.allRoles = await queryAllRolesWithPermissions()
        this.isLoading = false
      } catch (e) {
        this.$Message.error(e.message)
      }
    },
    edit(row) {
      this.dialogMode = "EDIT"
      this.dialogTitle = `编辑角色: ${row.name}`
      this.dialogDisplay = true
      this.$refs.roleEditor.formData = Object.assign({}, row)
    },
    async deleteByAlias(alias) {
      this.$Modal.confirm({
        content: "确认删除吗?",
        onOk: () => {
          apiUtil.call(this, async () => {
            await deleteRole(alias)
            await this.reload()
          })
        },
      })
    },
    async onFormSubmit() {
      if (this.dialogMode == "NEW") {
        apiUtil.call(this, async () => {
          await createRole(this.$refs.roleEditor.formData)
        })
      } else {
        apiUtil.call(this, async () => {
          await updateRole(this.$refs.roleEditor.formData)
        })
      }
      await this.reload()
    },
  },
}
</script>
